export function trackFormSubmission({ formTitle }) {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: 'formSubmission',
    formTitle,
  });
}

export function trackFormPdfDownload({ pdfTitle }) {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push({
    event: 'pdfDownload',
    pdfTitle,
  });
}


export function attachFormEvents() {
  const forms = document.querySelectorAll('form.wl-form');
  forms.forEach((form) => {
    form.addEventListener('form:sent', (e) => {
      trackFormSubmission({ formTitle: form.dataset.formTitle || 'Unknown Form' });
    });
  });
}

export function attachPdfDownloadsEvents() {
  const pdfLinks = document.querySelectorAll('a[data-file]');

  pdfLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      trackFormPdfDownload({ pdfTitle: link.dataset.file || 'Unknown PDF' });
    });
  });
}
